import React from 'react';
import { BsGithub, BsInstagram, BsLinkedin } from 'react-icons/bs';

const SocialMedia = () => {
  return (
  <div className="app__social">
   <a href = "https://www.linkedin.com/in/alibarangunduz/" rel="Ali Baran Gündüz LinkedIn" > 
    <div>
      <BsLinkedin /> 
    </div>
    </a>
    <div>
    <a href = "https://github.com/alibarangunduz" ><BsGithub /></a>
    </div>
    <div>
    <a href = "https://www.instagram.com/alibarangunduz_/" > <BsInstagram /></a>
    </div>
  </div>
  )
}

export default SocialMedia